var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('toolbar',{attrs:{"toolbarName":_vm.dynamicToolbarName,"openDetails":true}}),(!_vm.openDetails)?_c('search-bar',{attrs:{"noSecondaryInput":false,"noTertiaryInput":false,"noSubTertiaryInput":_vm.noSubTertiaryInput,"noFourthInput":false,"smallFields":""},on:{"externalFiltersClear":_vm.clearFilters},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.authoritySlug !== 'success-advisor')?_c('template',{slot:"tertiary-input"},[_c('simple-select',{staticClass:"mr-2",attrs:{"items":_vm.rankingTypes,"itemText":"text","itemValue":"value","height":"0","menuProps":{ 
                    bottom: true, 
                    offsetY: true, 
                    closeOnClick: true, 
                    maxHeight: 200 
                }},on:{"custom-change":function($event){return _vm.changeRankingType()}},model:{value:(_vm.selectedRankingType),callback:function ($$v) {_vm.selectedRankingType=$$v},expression:"selectedRankingType"}})],1):_vm._e(),(_vm.authoritySlug !== 'success-advisor')?_c('template',{slot:"sub-tertiary-input"},[_c('v-form',{ref:"subField"},[(_vm.selectedRankingType === 'regional_ranking')?_c('simple-select',{staticClass:"mr-2",staticStyle:{"margin-top":"0.93rem"},attrs:{"items":_vm.successAdvisors,"itemText":"name","itemValue":"id","placeholder":"Selecione AS","height":"0","menuProps":{ 
                        bottom: true, 
                        offsetY: true, 
                        closeOnClick: true, 
                        maxHeight: 200 
                    },"required":"","rules":_vm.requiredRules},on:{"custom-change":function($event){return _vm.loadRegionalPlotData()}},model:{value:(_vm.selectedAS),callback:function ($$v) {_vm.selectedAS=$$v},expression:"selectedAS"}}):_vm._e(),(_vm.selectedRankingType === 'state_ranking')?_c('simple-select',{staticClass:"mr-2",staticStyle:{"margin-top":"0.93rem"},attrs:{"items":_vm.states,"itemText":"name","itemValue":"id","placeholder":"Selecione Estado","height":"0","menuProps":{ 
                        bottom: true, 
                        offsetY: true, 
                        closeOnClick: true, 
                        maxHeight: 200 
                    },"required":"","rules":_vm.requiredRules},on:{"custom-change":function($event){return _vm.loadStatePlotData()}},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}}):_vm._e()],1)],1):_vm._e(),_c('template',{slot:"fourth-input"},[_c('simple-select',{attrs:{"items":_vm.harvests,"itemText":"harvest","itemValue":"harvest","placeholder":"Safra","height":"0","menuProps":{ 
                    bottom: true, 
                    offsetY: true, 
                    closeOnClick: true, 
                    maxHeight: 200 
                }},on:{"custom-change":function($event){return _vm.changeHarvest()}},model:{value:(_vm.currentHarvest),callback:function ($$v) {_vm.currentHarvest=$$v},expression:"currentHarvest"}})],1)],2):_vm._e(),(_vm.fetchingCultures)?_c('v-tabs',{staticClass:"font-weight-bold",attrs:{"fixed-tabs":"","background-color":"#e3e3e3","ligth":"","hide-slider":""}},[_c('v-tab',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_c('v-tabs',{staticClass:"font-weight-bold",attrs:{"fixed-tabs":"","background-color":"#e3e3e3","ligth":""},on:{"change":function($event){return _vm.changeCulture()}},model:{value:(_vm.selectedCulture),callback:function ($$v) {_vm.selectedCulture=$$v},expression:"selectedCulture"}},_vm._l((_vm.cultures),function(culture){return _c('v-tab',{key:`culture${culture.id}`,attrs:{"value":culture.name}},[_vm._v(" "+_vm._s(culture.name)+" ")])}),1),_c('v-data-table',{key:_vm.componentKey,staticClass:"px-6 pt-6 yellow-border rounded-xl",attrs:{"footer-props":{
            'items-per-page-text': 'Linhas por página',
            'items-per-page-all-text': 'Todos',
        },"headers":_vm.headers,"items":_vm.filteredData,"search":_vm.search.text,"loading":_vm.fetching,"loading-text":_vm.objectName ?
            'Carregando dados de ' + _vm.objectName + ', por favor aguarde...' :
            'Carregando dados, por favor aguarde...',"no-data-text":"Não foram encontrados registros.","no-results-text":"Nenhum registro bate com a busca."}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }