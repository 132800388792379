<template>
    <div>
        <toolbar :toolbarName="dynamicToolbarName" :openDetails="true" />

        <search-bar v-if="!openDetails" 
            v-model="search"
            @externalFiltersClear="clearFilters" 
            :noSecondaryInput="false"
            :noTertiaryInput="false" 
            :noSubTertiaryInput="noSubTertiaryInput" 
            :noFourthInput="false" smallFields
        >
            <template 
                v-if="authoritySlug !== 'success-advisor'" slot="tertiary-input">
                <simple-select v-model="selectedRankingType" 
                    :items="rankingTypes" 
                    itemText="text" 
                    itemValue="value"
                    class="mr-2" 
                    height="0"
                    :menuProps="{ 
                        bottom: true, 
                        offsetY: true, 
                        closeOnClick: true, 
                        maxHeight: 200 
                    }"
                    @custom-change="changeRankingType()" />
            </template>
            <template 
                v-if="authoritySlug !== 'success-advisor'"
                slot="sub-tertiary-input"
            >
                <v-form ref="subField">
                    <simple-select v-if="selectedRankingType === 'regional_ranking'" 
                        v-model="selectedAS"
                        :items="successAdvisors" 
                        itemText="name" 
                        itemValue="id" 
                        style="margin-top: 0.93rem;"
                        class="mr-2" placeholder="Selecione AS" 
                        height="0"
                        :menuProps="{ 
                            bottom: true, 
                            offsetY: true, 
                            closeOnClick: true, 
                            maxHeight: 200 
                        }" 
                        required
                        :rules="requiredRules" 
                        @custom-change="loadRegionalPlotData()" 
                    />
                    <simple-select 
                        v-if="selectedRankingType === 'state_ranking'" v-model="selectedState"
                        :items="states" 
                        itemText="name" 
                        itemValue="id" 
                        style="margin-top: 0.93rem;" 
                        class="mr-2"
                        placeholder="Selecione Estado" 
                        height="0"
                        :menuProps="{ 
                            bottom: true, 
                            offsetY: true, 
                            closeOnClick: true, 
                            maxHeight: 200 
                        }" 
                        required
                        :rules="requiredRules" 
                        @custom-change="loadStatePlotData()" 
                    />
                </v-form>
            </template>

            <template slot="fourth-input">
                <simple-select 
                    v-model="currentHarvest" 
                    :items="harvests"
                    itemText="harvest" 
                    itemValue="harvest" 
                    placeholder="Safra" 
                    height="0"
                    :menuProps="{ 
                        bottom: true, 
                        offsetY: true, 
                        closeOnClick: true, 
                        maxHeight: 200 
                    }"
                    @custom-change="changeHarvest()" />
            </template>
        </search-bar>

        <v-tabs
            v-if="fetchingCultures"
            fixed-tabs
            background-color="#e3e3e3"
            ligth
            hide-slider
            class="font-weight-bold"
        >
            <v-tab>
                <v-progress-circular
                indeterminate
                color="primary"
                ></v-progress-circular>
            </v-tab>
        </v-tabs>

            
        <v-tabs
            v-else
            fixed-tabs
            background-color="#e3e3e3"
            ligth
            v-model="selectedCulture"
            class="font-weight-bold"
            @change="changeCulture()"
        >
            <v-tab 
                v-for="culture in cultures" :key="`culture${culture.id}`"
                :value="culture.name">
                {{culture.name}}
            </v-tab>     
        </v-tabs>

        <v-data-table 
            :key="componentKey" :footer-props="{
                'items-per-page-text': 'Linhas por página',
                'items-per-page-all-text': 'Todos',
            }"
            :headers="headers" 
            :items="filteredData" 
            :search="search.text" 
            :loading="fetching" 
            :loading-text="objectName ?
                'Carregando dados de ' + objectName + ', por favor aguarde...' :
                'Carregando dados, por favor aguarde...'" 
            class="px-6 pt-6 yellow-border rounded-xl"
            no-data-text="Não foram encontrados registros." 
            no-results-text="Nenhum registro bate com a busca."
        >

        </v-data-table>
    </div>
</template>
  
<script>
import Toolbar from "@/components/crud/Toolbar";
import SearchBar from "@/components/layouts/SearchBar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";

import Harvests from "@/domain/harvests/harvests";
import Users from "@/domain/users/users";
import PlotRecord from "@/domain/records/plot-records/plot-records";
import Cultures from "@/domain/cultures/cultures"
import State from "@/domain/states/states"
import LoginService from "@/domain/login/login.service"


import PageableMixin from "@/support/utils/pageable.mixin";
import { getItem } from '@/services/local-storage.service';

export default {
    name: 'ProductivityRanking',

    mixins: [PageableMixin],

    components: {
        Toolbar,
        SearchBar,
        SimpleSelect
    },

    data: () => ({
        dynamicToolbarName: 'Ranking de Produtividade de Fazendas',
        openDetails: false,
        objectName: 'ranking de fazendas',

        search: {},
        fetching: true,
        fetchingCultures: true,

        headers: [
            { text: 'Posição', value: 'average_productivity.position', sortable: true },
            { text: 'Fazenda', value: 'farm.name', sortable: false },
            { text: 'Proprietário', value: 'producer.user.name', sortable: false },
            { text: 'Produtividade Média', value: 'average_productivity.average_productivity', sortable: true },
        ],

        selectedCulture: 0,
        selectedRankingType: 'national_ranking',
        currentHarvest: null,
        selectedAS: null,
        selectedState: null,
        selectedRegionalRanking: false,

        rankingTypes: [
            { text: 'Ranking Nacional', value: 'national_ranking' },
            { text: 'Ranking Regional', value: 'regional_ranking' },
            { text: 'Ranking Estadual', value: 'state_ranking' },
        ],

        plotRecordService: null,
        harvestService: null,
        userService: null,
        culturesService: null,
        statesService: null,
        loginService:null,

        payload: {},
        data: [],
        filteredData:[],
        harvests: [],
        successAdvisors: [],
        states: [],
        cultures: [],

        authoritySlug: '',
        permissions: '',

        componentKey: 0,
        requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"],
        noSubTertiaryInput: true,
    }),

    methods: {
        async loadHarvests() {
            this.harvests= [];
            await this.plotRecordService.list().then(result => {
                this.harvests = result.data.map(data=>data.harvest);
            }).catch(({ response }) => {
                const { error } = response.data;
                this.$toasted.show(error, {
                    type: 'error'
                });
            });
        },

        async loadNationalPlotData() {
            this.resetData();
            await this.plotRecordService.getRankingNational(this.currentHarvest)
                .then(result => {
                    this.data = result.data.data;
                })
                .catch((err) => {
                    this.fetching = false;
                    const { error } = err.response.data;
                    this.$toasted.show(error, {
                        type: 'error'
                    });
                });

            this.fetching = false;
            this.changeCulture();
        },

        async loadRegionalPlotData() {
            this.resetData();
            await this.plotRecordService.getRankingRegional(this.currentHarvest, this.selectedAS)
                .then(result => {
                    this.data = result.data.data;
                })
                .catch((err) => {
                    this.fetching = false;
                    const { error } = err.response.data;
                    this.$toasted.show(error, {
                        type: 'error'
                    });
                });

            this.fetching = false;
            this.changeCulture();
        },

        async loadStatePlotData() {
            this.resetData();
            await this.plotRecordService.getRankingState(this.currentHarvest, this.selectedState)
                .then(result => {
                    this.data = result.data.data;
                })
                .catch((err) => {
                    this.fetching = false;
                    const { error } = err.response;
                    this.$toasted.show(error, {
                        type: 'error'
                    });
                });

            this.fetching = false;
            this.changeCulture();
        },

        loadSuccessAdvisors() {
            const loader = this.$loading.show();
            this.successAdvisors = [];

            this.userService.getSuccessAdvisors()
                .then((result) => {
                    result.data.map((itm) => {
                        this.successAdvisors.push(itm);
                    })
                });

            loader.hide();
        },
        async loadStates(){
            try{
                const { data } = await this.statesService.list();
                
                this.states = data;      
            }catch(err){
                this.$toasted.show(err.response, {
                    type: "error",
                });
            }
        },

        async loadCultures() {
            try{
                const { data } = await this.culturesService.all()
                
                this.cultures = data
                this.selectedCulture = this.cultures[0];        
            }catch(err){
                this.$toasted.show(err.response, {
                    type: "error",
                });
            }finally{
                this.fetchingCultures = false;
            }
        },

        changeCulture() {
            const culture = this.cultures[this.selectedCulture]
            let filtered = [];
            this.filteredData = [];

            filtered = this.data.map(record => {
                const newRecord = { ...record }

                if(record.average_productivity && record.average_productivity[culture.name]?.average_productivity){
                    newRecord.average_productivity = {
                        'average_productivity': 
                            (record.average_productivity[culture.name].average_productivity).toFixed(1),
                        'position':
                            record.average_productivity[culture.name].position,
                        }
                }
                else {
                    newRecord.average_productivity = {
                        'average_productivity': '--',
                        'position': '--',
                    };
                }
                return (newRecord);
            })

            this.filteredData = filtered.sort((a, b) => {
                const valueA = a.average_productivity.average_productivity;
                const valueB = b.average_productivity.average_productivity;

                if (valueA === '--') return 1
                if (valueB === '--') return -1
                return Number(valueB) - Number(valueA)
            })
        },

        changeRankingType() {
            this.selectedAS = null;
            this.selectedState = null;

            if (this.selectedRankingType === 'national_ranking') {
                this.noSubTertiaryInput = true;
                this.loadNationalPlotData();
            } else {
                this.noSubTertiaryInput = false;
            }
        },

        async changeHarvest() {
            this.filteredData=[]
            if (this.selectedRankingType !== 'national_ranking' &&
                !this.$refs.subField.validate()) {
                return;
            }
            this.selectedRankingType === 'national_ranking' && await this.loadNationalPlotData();
            this.selectedRankingType === 'regional_ranking' && await this.loadRegionalPlotData();
            this.selectedRankingType === 'state_ranking' && await this.loadStatePlotData();
        },

        getCurrentHarvest() {
            const date = new Date();
            let year = date.getFullYear();
            const month = date.getMonth() + 1;

            // safra (ano da colheita) vai de agosto a julho
            if ([8, 9, 10, 11, 12].includes(month)) {
                this.currentHarvest = year + 1;
            } else if ([1, 2, 3, 4, 5, 6, 7].includes(month)) {
                this.currentHarvest = year;
            }
        },

        async initialize() {
            this.harvestService = Harvests;
            this.userService = Users;
            this.plotRecordService = PlotRecord;
            this.culturesService = Cultures;
            this.statesService = State;
            this.loginService = LoginService;

            this.getCurrentHarvest();

            if(this.authoritySlug === 'success-advisor'){
                return await this.initializeAS()
            }

            await Promise.all([
                this.loadCultures(),
                this.loadNationalPlotData()
            ]) 

            this.loadHarvests();
            this.loadStates();
            this.loadSuccessAdvisors();
        },
        async initializeAS(){
            await Promise.all([
                this.loadCultures(),
            ])

            const {id} = await LoginService.getUserLogged()
            
            this.selectedAS=id;

            this.loadHarvests();
            this.loadSuccessAdvisors();

            this.loadRegionalPlotData();
        },

        clearFilters() {
            this.search = {};

            const harvest = this.currentHarvest;
            this.getCurrentHarvest();

            if (this.selectedRankingType !== 'national_ranking' ||
                harvest !== this.currentHarvest) {
                this.componentKey += 1;
                this.selectedRankingType = 'national_ranking';
            }
        },

        resetData(){
            this.data=[]
            this.filteredData=[]
            this.fetching = true;
        }
    },

    beforeMount() {
        this.authoritySlug = getItem('authoritySlug');
        this.permissions = JSON.parse(getItem('permissions'));
        this.initialize();
    }
}
</script>
  
<style scoped>
.v-application a {
    color: #262532;
}

::v-deep table {
    overflow: hidden;
}

::v-deep th {
    white-space: nowrap;
}

::v-deep th:last-of-type,
::v-deep td:last-of-type {
    padding-right: 0px !important;
}

::v-deep .custom-tertiary-input+.v-card {
    width: 120px !important;
}
</style>